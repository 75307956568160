import * as FilePond from 'filepond';
import * as utils from './galcom-utils.js';


//import 'filepond/dist/filepond.min.css';

console.log("GAL3 this is galcom.js");

frappe.provide("galcom.utils");
window.galcom.utils = utils;
window.FilePond=FilePond;

var overrideFunctions = function(){
	console.log("GAL overrideFunction in galcom.js");
	var parent_bind_events=shopping_cart.bind_events;


	//remove "Pay" buttons
	console.log("hiding #pay-for-order");
	jQuery("#pay-for-order").hide();

	class GalcomProductGrid extends webshop.ProductGrid {

		get_primary_button(item, settings) {
			if (item.has_variants || item.item_group.includes("Player")) {
				return `
					<a href="/${ item.route || '#' }">
						<div class="btn btn-sm btn-explore-variants w-100 mt-4">
							${ __('Configure') }
						</div>
					</a>
				`;
			}else
				return super.get_primary_button(item,settings);
		}
	}
	webshop.ProductGrid = GalcomProductGrid;

	class GalcomProductList extends webshop.ProductList {
		get_primary_button(item, settings) {
			if (item.has_variants || item.item_group.includes("Player")) {
				return `
					<a href="/${ item.route || '#' }">
						<div class="btn btn-sm btn-explore-variants btn mb-0 mt-0">
							${ __('Configure') }
						</div>
					</a>
				`;
			}else
				return super.get_primary_button(item,settings);
		}
	}
	webshop.ProductList = GalcomProductList;

	$.extend(shopping_cart, {
		
		bind_events: function(){
			//console.log("GAL existing bind_events: "+parent_bind_events);
			parent_bind_events();
			shopping_cart.bind_financial_assistance();
			shopping_cart.bind_dest_country();
		},
		update_cart: function(opts) {
			console.log("GAL update_cart, new version",opts);
			if (frappe.session.user==="Guest") {
				if (localStorage) {
					localStorage.setItem("last_visited", window.location.pathname);
				}
				frappe.call('webshop.webshop.api.get_guest_redirect_on_action').then((res) => {
					window.location.href = res.message || "/login";
				});
			} else {
				shopping_cart.freeze();
				return frappe.call({
					type: "POST",
					method: "webshop.webshop.shopping_cart.cart.update_cart",
					args: {
						item_code: opts.item_code,
						qty: opts.qty,
						additional_notes: opts.additional_notes !== undefined ? opts.additional_notes : undefined,
						with_items: opts.with_items || 0,
						name: opts.name,
						custom_fields: opts.custom_fields
					},
					btn: opts.btn,
					callback: function(r) {
						shopping_cart.unfreeze();
						shopping_cart.set_cart_count(true);
						if(opts.callback)
							opts.callback(r);
					}
				});
			}
		},
		bind_change_qty: function() {
			console.log("GAL bind_change_qty, new version");
			// bind update button
			$(".cart-items").on("change", ".cart-qty", function() {
				var btn = $(this);
				var input = btn.closest('.number-spinner').find('input');
				let notes = input.closest("td").siblings().find(".notes").text().trim();
				var item_code = $(this).attr("data-item-code");
				var name = $(this).attr("data-name");
				var newVal = $(this).val();
				shopping_cart.shopping_cart_update({
                    item_code, 
                    qty: newVal,
					additional_notes: notes,
                    name:name
                });
			});

			$(".cart-items").on('click', '.number-spinner button', function () {
				var btn = $(this),
					input = btn.closest('.number-spinner').find('input'),
					oldValue = input.val().trim(),
					newVal = 0;

				if (btn.attr('data-dir') == 'up') {
					newVal = parseInt(oldValue) + 1;
				} else {
					if (oldValue > 1) {
						newVal = parseInt(oldValue) - 1;
					}
				}
				input.val(newVal);

				let notes = input.closest("td").siblings().find(".notes").text().trim();
				var item_code = input.attr("data-item-code");
				var name = input.attr("data-name");
				shopping_cart.shopping_cart_update({
					item_code,
					qty: newVal,
					additional_notes: notes,
                    name:name
				});
			});
		},
        bind_remove_cart_item: function() {
            $(".cart-items").on("click", ".remove-cart-item", (e) => {
                const $remove_cart_item_btn = $(e.currentTarget);
                var item_code = $remove_cart_item_btn.data("item-code");
                var name = $remove_cart_item_btn.data("name");

                shopping_cart.shopping_cart_update({
                    item_code: item_code,
                    name: name,
                    qty: 0
                });
            });
        },
        shopping_cart_update: function({item_code, qty, cart_dropdown, additional_notes,
									name,financial_assistance,custom_destination_country}) {
            shopping_cart.update_cart({
                item_code,
                qty,
                additional_notes,
                with_items: 1,
                name:name,
				custom_fields: {
					financial_assistance:financial_assistance,
					custom_destination_country: custom_destination_country
				},
                btn: this,
                callback: function(r) {
                    if(!r.exc) {
                        $(".cart-items").html(r.message.items);
                        $(".cart-tax-items").html(r.message.total);
                        $(".payment-summary").html(r.message.taxes_and_totals);
                        shopping_cart.set_cart_count();

                        if (cart_dropdown != true) {
                            $(".cart-icon").hide();
                        }
                    }
                },
            });
        },
		bind_financial_assistance: function() {
			$('.payment-summary').on('click','#financial_assistance', function() {
				const value = this.checked;
				shopping_cart.shopping_cart_update({financial_assistance:value});
			});
		},

		bind_dest_country: function() {
			$('.payment-summary').on('change','#custom_destination_country', function() {
				const value = $(this).val();
				shopping_cart.shopping_cart_update({custom_destination_country:value});
			});
		},

		set_error: function(message){
			$("#cart-error")
				.empty()
				.html(message)
				.toggle(true);
		},
		clear_error: function(){
			$("#cart-error").empty().toggle(false);
		},

		bind_request_quotation: function() {
			$('.payment-summary').on('click','.btn-request-for-quotation', function() {
				//do some validations first
				const dest_country = $("#custom_destination_country");
				if( ! dest_country.val()){
					shopping_cart.set_error("Final Destination Country not set.")
					dest_country.toggleClass('is-invalid',true);
					dest_country.focus();
					return;
				}else{
					shopping_cart.clear_error();
					dest_country.toggleClass('is-invalid',false);
				}


				shopping_cart.request_quotation(this)
					.catch(e =>{
						const ex = e.responseJSON;
						console.error("got exception: ",ex);
						if(ex.exc){
							shopping_cart.unfreeze();
							shopping_cart.set_error(
								(ex.exception || frappe._("Something went wrong!")).replace("frappe.exceptions.",""));
						}

					});
			});
		},

		bind_add_to_cart_action: function(){
			console.log(" OVERRIDDEN bind_action_to_cart_action");
			$('.page_content').
				on('click', ".btn-add-to-cart-list", (e) => {

					console.log("Click on add-to-cart ----------");
					const $btn = $(e.currentTarget);
					if($btn.hasClass("go-to-cart")|| $btn.hasClass("go-to-cart-grid"))
						return;
					$btn.prop('disabled', true);
					console.log("target: ",$btn);

					if (frappe.session.user==="Guest") {
						if (localStorage) {
							localStorage.setItem("last_visited", window.location.pathname);
						}
						frappe.call('webshop.webshop.api.get_guest_redirect_on_action').then((res) => {
							window.location.href = res.message || "/login";
						});
						return;
					}

					$btn.addClass('hidden');
					$btn.closest('.cart-action-container').addClass('d-flex');
					$btn.parent().find('.go-to-cart').removeClass('hidden');
					$btn.parent().find('.go-to-cart-grid').removeClass('hidden');
					$btn.parent().find('.cart-indicator').removeClass('hidden');

					const item_code = $btn.data('item-code');
					webshop.webshop.shopping_cart.update_cart({
						item_code,
						qty: 1
					});

			});
		}


	});

}
var checkForParent = function(count=10){
	if(count===0){
		console.error("GAL failed to detect parent init of shopping_cart after 10 tries");
		return;
	}
	
	if( ! shopping_cart.bind_change_qty){
		setTimeout( () =>{
			checkForParent(count-1)
		},1000);
	}else {
		overrideFunctions();
	}
}
frappe.provide("webshop.webshop.shopping_cart");
var shopping_cart = webshop.webshop.shopping_cart;

//checkForParent();

frappe.ready(()=>{
	console.log("GAL galcom in frappe.ready");


	overrideFunctions();
});
