//freqRE: /^\s*(\d+(\.\d+)?\s*(AM|FM|SW))(\s*\/\s*(\d+(\.\d+)?\s*(AM|FM|SW)))*\s*$/i,
const freqRE= /^\s*(\d+(\.\d+)?)\s*(AM|FM|SW|khz|mhz)\s*$/i // use after spliting on '/'
const validRanges=[
    [525,1705], // 0 AM, MW

    [2300,2500], // 1 AM, SW 
    [3200,3400],
    [3900,4000],
    [4750,5060],
    [5900,6200],
    [7200,7600],
    [9400,9900],
    [11600,12100],
    [13570,13870],
    [15100,15800],
    [17480,17900],
    [18900,19020],
    [21450,21850],
    [25600,26100],

    [65000,108000], // 15 FM
]
export function kilohertzToBand(khz){
    if(khz >= validRanges[0][0] && khz <= validRanges[0][1])
        return "MW";

    if(khz >= validRanges[1][0] && khz <= validRanges[14][1])
        return "SW";

    if(khz >= validRanges[15][0] && khz <= validRanges[15][1])
        return "FM";

    return "";
}
export function checkFrequencyString(freqString,maxCount=null,splitChar="/"){

    if(freqString == null)
        return { valid: false, message: "No input given", }

    const sets = freqString.split(splitChar);
    
    if(maxCount != null && sets.length > maxCount){
        return {
            valid: false,
            message: `Only ${maxCount} frequences allowed, but ${sets.length} given: ${freqString}`
        }
    }
    return sets.map( freq =>{
        var matches = freq.match(freqRE)
        //  matches == [whole match, numeric part, ignore, band]

        if(matches == null){
            return {
                valid: false,
                //message: `'${freq}' does not match pattern '{frequncy value} {one of AM, FM, SW}'. Multiple frequencies can be separated by a '${splitChar}'.`
                message: `Sorry, '${freq}' is not valid. Please enter frequencies like '101.4 FM, 565 AM'`
            }
        }
        var value = parseFloat(matches[1]);
        const band = matches[3].toUpperCase();
        if(band === "FM" || band === "MHZ" || (band === "SW" && value >= 1.6 && value <= 30)){ //convert FM to kHz, or SW in range 1.6-30
            value = value * 1000.0;
        }
        //see if we are in the correct range
        const inRange = validRanges.map(range =>  value >= range[0] && value <= range[1] ).filter(x => x).length !==0;
        if(inRange){
            return { valid: true,
                kilohertz: value,
                band: kilohertzToBand(value)}; //get a normalized band 
        }else{
            return {
                valid: false,
                message: `${freq} is not in a valid range`
            }
        }
    }).reduce((final,current)=>{
        //if all are valid, returns union of ranges (as [min,max]), and array of bands
        // if any are not valid, sets valid to false, and then appends all failure messages
        console.log("final, current",final,current);
        if(current.valid){
            if( ! final.bands.includes(current.band)) //new band
                final.bands.push(current.band)

            final.kilohertz_range = [ Math.min(final.kilohertz_range[0],current.kilohertz),
                                        Math.max(final.kilohertz_range[0],current.kilohertz) ]

            final.count = final.count + 1;
                                
            return final;
        }else{
            final.valid=false;
            final.message = (final.message || "")+"<p/>"+current.message;
            return final;
        }
    },{
        kilohertz_range:[200000,0],
        count: 0,
        bands:[],
        message:"",
        valid: true,
    })
}
export function delay(fn, ms) {
    let timer = 0
    return function(...args) {
        clearTimeout(timer)
        timer = setTimeout(fn.bind(this, ...args), ms || 0)
    }
}

export function saveGuestFields(storageName,fieldNames){
    if(frappe.session.user !== "Guest")
        return;

    var data = {};
    for(const fieldName of fieldNames){
        data[fieldName] = $("."+fieldName).val();
    }
    localStorage.setItem(storageName,JSON.stringify(data));
}
export function restoreGuestFields(storageName, fieldNames){
    try{
        const data = JSON.parse(localStorage.getItem(storageName));
        if(data){
            for(const fieldName of fieldNames){
                $("."+fieldName).val(data[fieldName]);
            }
            localStorage.removeItem(storageName);
        }
    }catch(error){
        console.warn("Failed to restore guest data: ",error);
        localStorage.removeItem(storageName);
    }

}